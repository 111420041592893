import request from '@/utils/request.js'

/**
 * 新增角色
 * @param {Object} data
 * @returns
 */
export function addRole(data) {
  return request({
    url: 'api/role/AddRole',
    method: 'post',
    data,
  })
}

/**
 * 获取角色列表
 * @param {Object} data
 * @returns
 */
export function getAllList(data) {
  return request({
    url: 'api/role/GetRoleList',
    method: 'post',
    data,
  })
}

/**
 * 获取所有角色信息
 * @returns
 */
export function getAllRole() {
  return request({
    url: 'api/role/getAllRole',
    method: 'get',
  })
}

/**
 * 删除角色
 * @param {int} id
 * @returns
 */
export function deleteRole(id) {
  return request({
    url: 'api/role/DeleteRole',
    method: 'get',
    params: { id },
  })
}

/**
 * 更新角色
 * @param {Object} data
 * @returns
 */
export function updateRole(data) {
  return request({
    url: 'api/role/Update',
    method: 'post',
    data,
  })
}

/**
 * 根据角色Id获取角色详细信息
 * @param {int} id
 * @returns
 */
export function getRoleInfoById(id) {
  return request({
    url: 'api/role/GetRoleInfo',
    method: 'get',
    params: { id },
  })
}
