<template>
  <el-card>
    <div slot="header">
      <span>用户管理</span>
    </div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
          v-model="queryFilter.keyWorlds"
          placeholder="请输入用户相关信息"
          clearable
        />
      </el-col>
      <el-col :span="6">
        <el-select
          v-model="queryFilter.status"
          placeholder="请选择用户身份"
          clearable
        >
          <el-option label="管理员" :value="1"></el-option>
          <el-option label="经销商" :value="2"></el-option>
          <el-option label="终端用户" :value="3"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select
          v-model="queryFilter.type"
          placeholder="请选择用户商城状态"
          clearable
        >
          <el-option label="正常" :value="1"></el-option>
          <el-option label="未审核" :value="0"></el-option>
        </el-select>
      </el-col>
      <el-button type="primary" @click="falshTable">查询用户</el-button>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table :data="userListData" stripe style="width: 100%" border>
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="userName" label="用户名"> </el-table-column>
        <el-table-column prop="mailUserRoleName" label="商城角色">
        </el-table-column>
        <el-table-column prop="userState" label="商城状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.userState == 1" type="success">正常</el-tag>
            <el-tag v-else type="danger">用户尚未通过审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column prop="phone" label="电话"> </el-table-column>
        <el-table-column prop="company" label="公司"> </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            <el-button type="text" @click="openChangeRoleDialog(scope.row)">
              {{ scope.row.roleName }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="是否允许登录">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.canLogin"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="switchUserState(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <el-dialog title="更改用户当前角色" :visible.sync="changRoleDialogVisabled">
      <el-form
        ref="changeRoleFormRef"
        :rules="changeRoleFormRules"
        v-model="changeRoleForm"
        label-width="100px"
      >
        <el-form-item prop="roleCode" label="角色:">
          <el-select v-model="changeRoleForm.roleCode" placeholder="请选择角色">
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.roleName"
              :value="item.roleCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="changRoleDialogVisabled = false">取消</el-button>
        <el-button type="primary" @click="changRoleDialogConfirm"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import { getUserList, changeUserState, updateUserRoleCode } from '@/api/user.js'
import { getAllRole } from '@/api/role.js'
export default {
  data() {
    return {
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
        type: null,
      },
      // 用户 list 数据
      userListData: [],
      // 角色配置选项
      roleOptions: [],
      changRoleDialogVisabled: false,
      changeRoleForm: {
        userId: null,
        roleCode: '',
      },
      changeRoleFormRules: {
        userId: [
          {
            required: true,
            message: '',
            trigger: 'blur',
          },
        ],
        roleCode: [
          {
            required: true,
            message: '',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.getUserListData()
    this.getAllRoleOptions()
  },
  methods: {
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getUserListData()
    },
    falshTable() {
      this.queryFilter.pageIndex = 1
      this.getUserListData()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getUserListData()
    },
    async getUserListData() {
      let { data } = await getUserList(this.queryFilter)

      this.userListData = data.data

      this.queryFilter.total = data.total
      // this.queryFilter.pageIndex = data.pageIndex
      // this.queryFilter.pageSize = data.pageSize
    },
    async switchUserState(userInfo) {
      if (userInfo.mailUserRoleName === '终端用户') {
        userInfo.canLogin = !userInfo.canLogin
        return this.$message.error('终端用户不被允许登录本系统.')
      }

      try {
        let { code } = await changeUserState({
          id: userInfo.id,
          state: userInfo.canLogin,
        })
        return this.$message.success('用户状态修改成功')
      } catch (error) {
        userInfo.canLogin = !userInfo.canLogin
        return this.$message.error('用户状态修改失败')
      }
    },
    async getAllRoleOptions() {
      let { data } = await getAllRole()
      this.roleOptions = data
    },
    openChangeRoleDialog(row) {
      if (row.mailUserRoleName === '终端用户') {
        return this.$message.error('终端用户不被允许登录本系统.')
      }

      this.changeRoleForm.userId = row.id
      this.changeRoleForm.roleCode = row.roleCode
      this.changRoleDialogVisabled = true
    },
    async changRoleDialogConfirm() {
      if (!this.changeRoleForm.userId || !this.changeRoleForm.roleCode) {
        return this.$message.error('当前角色信息不完整')
      }

      await updateUserRoleCode(this.changeRoleForm)
      this.$message.success('更新用户角色信息成功')
      this.getUserListData()
      this.changRoleDialogVisabled = false
    },
  },
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
